.lia-state-wrapper {
  width: fit-content;
  margin-top: 4px;
}
.lia-message-state-text,
.lia-message-schedule-text {
  color: var(--lia-bs-gray-900);
  padding: 5px;
  border-radius: var(--lia-bs-border-radius-sm);
  font-size: var(--lia-font-size-xxs);
  font-weight: var(--lia-font-weight-md);
  letter-spacing: 0.5px;
}

.lia-schedule-tooltip {
  [class*='tooltip-inner'] {
    background-color: var(--lia-bs-gray-300);
    color: var(--lia-bs-gray-800);
    font-size: var(--lia-font-size-xs);
    font-weight: var(--lia-font-weight-md);
    display: inline;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 0;
    border: 1px solid var(--lia-bs-border-color);
  }
  [class*='arrow'] {
    visibility: hidden;
  }
}

.lia-message-schedule-text:hover {
  cursor: default;
}
